<template>
    <div>
    <b-card no-body>
        <div class="m-2 p-1 form-boder-input">
            <b-row>
                <b-col class="d-flex justify-content-start">
                    <h4 class="text-dark font-weight-bold">รายการคิว</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3">
                    <div class="demo-vertical-spacing ml-1">
                        <b-input-group class="input-group-merge mt-2">
                            <b-form-input placeholder="ค้นหาเลขที่ OPD" v-model="searchQuery" />
                            <b-input-group-append is-text>
                                <feather-icon icon="SearchIcon" color="#58ADEB" />
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col md="3">
                    <div class="demo-vertical-spacing ml-1">
                        <b-form-group
                            label="วันที่"
                            label-for="date"
                            >
                            <b-form-datepicker
                                id="date"
                                v-model="searchDate"
                                type="date"
                            />
                        </b-form-group>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="m-2">
            
            <vue-good-table
            :columns="tableColumns"
            :rows="Data"
           
            ref="refListTable"
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >
                <span v-if="props.column.field === 'code'">
                    <b-button
                 
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-mute"
                    class="btn-icon rounded-circle" 
                    
                    :to="{ name: 'queue-detail', params:{id:props.row.id}}"

                >
                {{ props.row.code }}
                </b-button>
                    
                </span>
                <span v-else-if="props.column.field === 'receipt_no'">
                    <b-button
                 
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle" 
                    
                    :to="{ name: 'receipt-confirm', params:{id:props.row.receipt_id}}"

                >
                {{ props.row.receipt_no }}
                </b-button>
                    
                </span>
                <span
                v-else-if="props.column.field === 'first_name'"
                class="text-nowrap"
                
                >   
                    <span class="text-nowrap">{{ props.row.first_name }} {{ props.row.last_name }} </span>
               
                </span>
                <span v-else-if="props.column.field === 'datetime'">
                    {{  formatDateThTime(props.row.datetime) }}
                    
                </span>
                <span
                v-else-if="props.column.field === 'status'"
                class="text-nowrap"
                
                >   
                    <span :class="`text-nowrap text-${resolveTreatmentStatusTextColorVariant(props.row)}`">{{ resolveTreatmentStatusTextVariant(props.row) }} </span>
               
                </span>
                <span
                v-else-if="props.column.field === 'check_status'"
                class="text-nowrap"
                
                >   
                    <span :class="`text-nowrap text-${resolveStatusTextColorVariant(props.row)}`">{{ resolveStatusTextVariant(props.row) }} </span>
               
                </span>
                <span
                v-else-if="props.column.field === 'action'"
                class="text-nowrap"
                >
                <b-tooltip :target="`tooltip-target0-${props.row.id}`" triggers="hover" placement="top">
                    เพิ่มคิวบริการ
                </b-tooltip>
                <b-button
                     v-if="props.row.receipt_status==4 || props.row.status == 3"
                    :id="`tooltip-target0-${props.row.id}`"
                  
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="`flat-primary`"
                    
                    class="btn-icon rounded-circle" 
                    @click="AddService(props.row)"
                 

                >
                    <feather-icon icon="PlusCircleIcon" />
                </b-button>
                <b-button
                     v-else
                    :id="`tooltip-target0-${props.row.id}`"
                   :disabled="true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="`flat-primary`"
                    class="btn-icon rounded-circle" 
                    @click="AddService(props.row)"
                 

                >
                    <feather-icon icon="PlusCircleIcon" />
                </b-button>
                <b-button
                    v-if="props.row.receipt_status == 4 || props.row.status == 3"
                    :id="`tooltip-target1-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-mute"
                    class="btn-icon rounded-circle" 
                    :disabled="true"
                   
                    :to="{ name: 'receipt-opd-create', params:{opdcard_id:props.row.opdcard_id}}"

                >
                    <feather-icon icon="FileTextIcon" />
                </b-button>
                <b-button
                    v-else-if="props.row.receipt_id"
                    :id="`tooltip-target1-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle" 
                    
                    @click="updateReceipt(props.row)"
                    

                >
                    <feather-icon icon="FileTextIcon" />
                </b-button>
                <b-button
                    v-else
                    :id="`tooltip-target1-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle" 
                    @click="createReceipt(props.row)"
                    

                >
                    <feather-icon icon="FileTextIcon" />
                </b-button>
               
                <b-tooltip :target="`tooltip-target1-${props.row.id}`" triggers="hover" placement="top">
                    สร้างใบเสร็จ
                </b-tooltip>
                <b-button
                    :id="`tooltip-target2-${props.row.id}`"
                    v-if="props.row.receipt_id || props.row.status == 3"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="`flat-${resolveStatusVariant(props.row.check_status)}`"
                    class="btn-icon rounded-circle" 
                    :disabled="true"
                    :to="{ name: 'room-detail', params:{queue_id:props.row.id}, query: { opdcard_id:parseInt(props.row.opdcard_id) }}"

                >
                    <feather-icon icon="CheckCircleIcon" />
                </b-button>
                <b-button
                    :id="`tooltip-target2-${props.row.id}`"
                    v-else
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="`flat-primary`"
                    class="btn-icon rounded-circle" 
                    :to="{ name: 'room-detail', params:{queue_id:props.row.id}, query: { opdcard_id:parseInt(props.row.opdcard_id) }}"

                >
                    <feather-icon icon="CheckCircleIcon" />
                </b-button>
                <b-tooltip :target="`tooltip-target2-${props.row.id}`" triggers="hover" placement="top">
                    ตรวจคนไข้
                </b-tooltip>
                <!--<b-button
                    :id="`tooltip-target3-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-mute"
                    class="btn-icon rounded-circle" 
                    :to="{ name: 'queue-detail', params:{id:props.row.id}}"

                >
                    <feather-icon icon="FilterIcon"  transform="rotate(-180)" />
                </b-button>
                <b-tooltip :target="`tooltip-target3-${props.row.id}`" triggers="hover" placement="top">
                    รายการทดสอบ
                </b-tooltip>-->
                <!--<b-button
                    :id="`tooltip-target4-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-mute"
                    class="btn-icon rounded-circle" 
                    :to="{ name: 'queue-detail', params:{id:props.row.id}}"

                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-tooltip :target="`tooltip-target4-${props.row.id}`" triggers="hover" placement="top">
                    แก้ไข
                </b-tooltip>-->
                <b-button
                    :id="`tooltip-target5-${props.row.id}`"
                    v-if="props.row.receipt_status==4 || props.row.status == 3"
                    
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    :to="{ name: 'receipt-confirm', params:{id:props.row.receipt_id}}"

                >
                    <feather-icon icon="PrinterIcon" />
                </b-button>
                <b-button
                    :id="`tooltip-target5-${props.row.id}`"
                    v-else
                    :disabled="true"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-mute"
                    class="btn-icon rounded-circle"
                    :to="{ name: 'receipt-confirm', params:{id:props.row.receipt_id}}"

                >
                    <feather-icon icon="PrinterIcon" />
                </b-button>
                <b-tooltip :target="`tooltip-target5-${props.row.id}`" triggers="hover" placement="top">
                    ปริ้นท์
                </b-tooltip>
                <b-button
                    :disabled="false"
                    v-if="!props.row.receipt_id && props.row.status != 3"
                    :id="`tooltip-target6-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="cancelconfirm(props.row.id)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                <b-button
                    :disabled="true"
                    v-else
                    :id="`tooltip-target6-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="cancelconfirm(props.row.id)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                
                <b-tooltip :target="`tooltip-target6-${props.row.id}`" triggers="hover" placement="top">
                    ยกเลิก
                </b-tooltip>
                <b-button
                    :disabled="false"
                    v-if="!props.row.receipt_id "
                    :id="`tooltip-target6-${props.row.id}`"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    @click="deleteconfirm(props.row.id)"
                >
                    <feather-icon icon="XIcon" />
                </b-button>
                <b-tooltip   v-if="!props.row.receipt_id " :target="`tooltip-target6-${props.row.id}`" triggers="hover" placement="top">
                    ลบ
                </b-tooltip>
                
                </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="Total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
            
        </div>

    </b-card>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import useQueueList from './useQueueList'
import queueStoreModule from '../queueStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import router from '@/router'
import { formatDateThTime,getCommaDecimal } from '@core/utils/filter'
import {  watch, computed } from '@vue/composition-api'
import roomDetailStoreModule from '@/views/room/room-detail/roomDetailStoreModule'
    //import roomDetailStoreModule from './roomDetailStoreModule.js'
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,
        VueGoodTable,
        vSelect

    },
    data(){
        return {
            formatDateThTime
        }
    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    watch:{
        $route() {
           this.fetchData();
        },
    },
    setup() {
    
    
        const ROOMS_STORE_MODULE_NAME = 'app-roomdetail';
            if (!store.hasModule(ROOMS_STORE_MODULE_NAME)) store.registerModule(ROOMS_STORE_MODULE_NAME, roomDetailStoreModule)
            onUnmounted(() => {
                if (store.hasModule(ROOMS_STORE_MODULE_NAME)) store.unregisterModule(ROOMS_STORE_MODULE_NAME)
            });
        const PATIENTS_STORE_MODULE_NAME = 'app-queue';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, queueStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            tableColumns,
            fetchData,
            deleteData,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            currentPage,
            refListTable,
            Patient_Data,
            Doctor_Data,
            Room_Data,
            searchDate,
            resolveStatusVariant,
            resolveStatusTextVariant,
            resolveStatusTextColorVariant,
            resolveTreatmentStatusTextVariant,
            resolveTreatmentStatusTextColorVariant,
            createReceipt,
            updateReceipt,
            cancelData,
            AddService

        } = useQueueList();
        
        return {
            updateReceipt,
            searchDate,
            tableColumns,
            fetchData,
            deleteData,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            currentPage,
            refListTable,
            Patient_Data,
            Doctor_Data,
            Room_Data,
            resolveStatusVariant,
            resolveStatusTextVariant,
            resolveStatusTextColorVariant,
            resolveTreatmentStatusTextVariant,
            resolveTreatmentStatusTextColorVariant,
            createReceipt,
            cancelData,
            AddService
        }

        
    },methods:{
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        cancelconfirm(id,title){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to cancel queue: '+id, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
           this.cancelData(id)
          }
        })

        },
        deleteconfirm(id,title){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete queue: '+id, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
           this.deleteData(id)
          }
        })

        },
    },
  /*  methods:{
            deleteconfirm(id){
                this.$bvModal
                    .msgBoxConfirm('Please confirm that you want to delete QUEUE ID.'+id, {
                    title: 'Please Confirm',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                    })
                    .then(value => {
                    if(value === true){
                    this.deleteData(id)
                    }
                
                    //this.deleteData(id)
                    })
    
            },
        }*/
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>